@use '@styles/variables' as *;

.section {
  &-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 12px;
  }

  &-title {
    font-size: 1.1rem;
    margin: 0 0 1em 0;
    width: fit-content;
  }
}
