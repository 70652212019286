.home {
  &-container {
    margin-bottom: 36px;
  }

  &-hero {
    min-height: 55vh;

    &-subsection {
      padding: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
