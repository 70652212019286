@use '@styles/variables' as *;

$timeline-color: $secondary;

.timeline {
  &-item {
    &:first-child {
      // FIXME: Hack to make the two timeline look the same
      min-height: 130px;
      box-sizing: content-box;

      padding-bottom: 1.5rem;
    }

    &-bullet {
      height: 10px;
      width: 10px;
      background-color: $timeline-color;
      border-radius: 50%;
      margin-left: -20px;
      margin-top: 38px;
      float: left;
    }

    &-container {
      display: flex;
      flex-direction: row;
    }

    &-image {
      height: 94px;
      width: 94px;
      max-height: 94px;
      min-width: 94px;
      margin-left: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 1.5rem;
    }
  }

  p {
    margin: 4px 0px;
  }

  ul {
    list-style-type: none;
    margin-bottom: 0px;
    padding-left: 0px;
  }

  li:before {
    background-color: $timeline-color;
    width: 2px;
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 4px;
  }

  li {
    /* You need to turn on relative positioning so the line is placed relative to the item rather than absolutely on the page */
    position: relative;

    /* Use padding to space things out rather than margins as the line would get broken up otherwise */
    margin: 0;
    padding-bottom: 1.5rem;
    padding-left: 20px;

    &:first-child {
      // Start the line further down on the first list item
      &:before {
        top: 38px;
      }
    }

    &:last-child {
      // Stop the line short on the final list item
      &:before {
        height: 38px;
      }

      padding-bottom: 0px;
    }
  }
}
