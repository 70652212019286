@use '@styles/variables' as *;

.aboutme {
  &-codeblock {
    height: 330px;
    overflow: auto;
    margin-bottom: 1.5rem;
    background-color: $code-background;
    border-radius: 2px;
    border: 1px solid $gray-600;

    #aboutme-sandbox {
      display: none;
    }
  }

  &-output {
    &-container {
      column-gap: 12px;
      display: flex;
      flex-direction: row;
    }

    &-controls {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      height: fit-content;
      row-gap: 16px;
    }
    &-area {
      flex-grow: 1;
      display: flex;
      height: 120px;
      overflow: auto;
      padding: 0.5rem 0.75rem;
      font-size: 0.8rem;
      background-color: lighten($color: $code-background, $amount: 7);
      border-radius: 2px;

      &-spinner {
        align-items: center;
        align-self: center;
        display: flex;
        flex-grow: 1;
        justify-content: center;
      }
    }
  }
}
