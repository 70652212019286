@use '@styles/variables' as *;

.codeblock {
  &-editor {
    counter-reset: line;

    &.line-numbers {
      #codeblock-editor-textarea {
        outline: none;
        padding-left: 60px !important;
      }

      pre {
        padding-left: 60px !important;
      }

      .editor-line-number {
        color: $gray-600;
        font-weight: 100;
        left: 0px;
        position: absolute;
        text-align: right;
        width: 40px;
      }
    }
  }
}
