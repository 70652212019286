// @use '@styles/bootstrap';

.hero {
  &-container {
    // @media (max-width: map-get(bootstrap.$grid-breakpoints, 'lg')) {
    //   margin-bottom: 3rem;
    // }
  }

  &-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    width: fit-content;
  }

  &-subtitle {
    font-size: 1.25rem;
    width: fit-content;
  }
}
