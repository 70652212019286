@use 'styles/variables' as *;
@import 'styles/styles';
@import 'styles/custom';

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $white;
  background-color: $background-color;
  min-height: 100vh;
}

code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
